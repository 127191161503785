import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import herbscoversingle from "../../../../assets/herbs-ecover-single.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const HerbsForHealth = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={herbscoversingle}
      heroLabel="Herbs for Health!"
      heroDescription="Here's just a taste of what's in Herbs for Health!"
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            The guide was created to be simple to use and to give you with all
            of the information you desire. There are two simple uses for this
            book. First, you might go through the index to see which herbs heal
            your condition, and then apply or consume the fresh herb.
          </p>
          <p>
            Each herb comes backed by a high-quality color image and a full
            investigation or analysis of the plant. This manner, you'll
            understand its benefits and why our forefathers employed it. The
            second method makes it simple to produce a tasty dinner with your
            selected herb. Simply look through the cookbook's index to discover
            the dish you want to make. There are 50 recipes included!
          </p>
          <p>
            This allows you to cook a meal for the entire family. The index will
            show you exactly which herb is used in the recipe, allowing you to
            get to the correct page in seconds. The nutritional value of each
            dish is there to ensure that your diet objectives are always
            satisfied. Whether you're a novice or a seasoned herbalist, this
            book will help you pick the proper herbs when you need them.
          </p>
          <p>Discover herbal remedies that could save your life.</p>

          <p>
            Are you ready to start growing our own backyard pharmacy? Click the
            button below and prepare to be amazed!
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/466293/ImportantMonk/"
            label="Get your guide"
          />
          <p>
            Rest assured knowing that all purchases are backed by{" "}
            <strong>60-day money-back</strong> guarantee.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default HerbsForHealth;
