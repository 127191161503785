import styled from "styled-components";

export const StyledBackButton = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: ${(props) => props.theme.ctaBtnBorderRadius};
  background-color: ${(props) => props.theme.primary};
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease-in-out;

  img {
    width: 35px;
  }
`;

export const StyledLabel = styled.p`
  font-size: 18px;
  margin-left: 15px;
  margin-bottom: 0;
`;

export const StyledBackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }

  &:hover ${StyledBackButton} {
    background-color: ${(props) => props.theme.primaryHover};
  }
`;
