import React from "react";

import { Col, Row } from "react-bootstrap";

import { homeModel } from "./model/homeModel";
import CategoryCardVariant from "../../components/CategoryCardVariant/CategoryCardVariant";

const Home = () => (
  <>
    <Row>
      <Col md={12} xl={8} className="mt-4 mb-5">
        <h1>
          Here you can discover limited-time offers, seasonal sales, and
          exclusive discounts that will help you save big on your favorite
          products.
        </h1>
      </Col>
    </Row>

    <Row className="mb-5">
      {homeModel.length &&
        homeModel.map(
          (
            { img, label, url, variant, description, sm, md, lg, xl, xxl },
            index
          ) => (
            <Col sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} key={index}>
              <CategoryCardVariant
                index={`${index} - ${label}`}
                img={img}
                label={label}
                url={url}
                variant={variant}
                description={description}
              />
            </Col>
          )
        )}
    </Row>
  </>
);

export default Home;
