import styled from "styled-components";

export const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  margin-top: 70px;
  font-size: 14px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    height: 1px;
    background-color: ${(props) => props.theme.primary};

    @media (max-width: 575px) {
      width: 100%;
    }
  }
`;
