import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import ketoSnacks from "../../../../assets/keto-snack-page.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const KetoSnacks = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={ketoSnacks}
      heroLabel="The Essential Keto Snacks Cookbook"
      heroDescription="The Essential Keto Snacks Cookbook is a culinary treasure trove
      for anyone following a ketogenic lifestyle or looking to explore
      low-carb, healthy snacking options. This cookbook is a celebration
      of guilt-free indulgence. It presents over 78 mouthwatering recipes
      that encompass a diverse range of savory and sweet snacks."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            From delectable keto fudge that rivals traditional versions to
            savory bacon-wrapped asparagus and crunchy keto chips, the cookbook
            offers a delightful array of choices for those seeking to satisfy
            their cravings without compromising their health. The recipes are
            designed to be simple and easy-to-follow, making them accessible to
            both seasoned cooks and kitchen novices.
          </p>
          <p>
            Moreover, the cookbook ensures that all the snacks are 100%
            keto-friendly, gluten-free, paleo, and free from inflammatory
            ingredients, catering to a wide range of dietary preferences and
            restrictions.
          </p>
          <p>
            "The Essential Keto Snacks Cookbook" stands out from other keto
            recipe collections due to its emphasis on using wholesome, readily
            available ingredients. Louise understands that simplicity is key to
            maintaining a sustainable keto lifestyle, and she has crafted these
            recipes to be both delicious and practical.
          </p>
          <p>
            The cookbook addresses the challenge of eating out on a ketogenic
            diet, providing valuable tips and a guide on how to make
            keto-friendly choices while dining at restaurants. Additionally, it
            introduces the concept of intermittent fasting (IF) and explains how
            combining IF with a keto diet can further enhance health and weight
            loss results.
          </p>
          <p>
            <strong>
              With a physical copy of the cookbook provided for free (while
              covering a small shipping cost), it is accessible to a wider
              audience, spreading the joy of keto snacking and empowering
              individuals to reclaim their health through delicious,
              nutrient-dense food choices.
            </strong>
          </p>

          <p>
            <strong>Click on a button below To Claim Your Free Copy!</strong>
          </p>

          <CTAButton
            url="https://www.digistore24.com/redir/400534/ImportantMonk/"
            label="Get your book"
          />
          <p>
            IRONCLAD <strong>60-DAY</strong> Triple Money-Back Guarantee.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default KetoSnacks;
