import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import lostSuperFoods from "../../../../assets/lost-superfood.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const LostSuperFoods = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={lostSuperFoods}
      heroLabel="The Lost Superfoods: Essential Survival Tips & Recipes"
      heroDescription="Long-Lasting Survival Foods: Discover over 126 forgotten superfoods
      and storage hacks to create a reliable survival stockpile without
      refrigeration. Perfect for emergencies like hurricanes or grid
      collapses."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            Learn how to make a secret military superfood, cost-effective and
            capable of keeping you well-fed for just 37 cents a day. Ideal for
            bug-out bags and long-term storage.
          </p>
          <p>
            Uncover ancient recipes like Viking superfoods, Civil War survival
            food, and more. Preserve meat, cheese, and even eggs for years
            without refrigeration.
          </p>
          <p>
            The Lost Superfoods guide provides step-by-step instructions,
            complete with pictures, for creating a long-lasting survival
            stockpile. You'll find 126 forgotten foods that require no
            refrigeration, making it easy for anyone to prepare for emergencies
            like pandemics or natural disasters.
          </p>
          <p>
            Learn the secrets of the U.S. Doomsday Ration, a military superfood
            developed during the Cold War. Inexpensive and durable, this food
            source can keep you well-fed for just 37 cents a day. It's perfect
            for emergencies and can be easily stored in your bug-out bag.
          </p>
          <p>
            Delve into the forgotten preservation methods that sustained past
            generations during tough times. From Viking superfoods to Civil War
            survival dishes, these recipes will help you preserve meat, cheese,
            and even eggs for extended periods without refrigeration.
          </p>
          <p>
            <strong>
              On Your Bookshelf Today and in Your Pantry Tomorrow!
            </strong>
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/377301/ImportantMonk/"
            label="Get your book"
          />
          <p>
            You have <strong>TWO MONTHS</strong> to test drive The Lost
            Superfoods and enjoy.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default LostSuperFoods;
