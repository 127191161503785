import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import homeDoctor from "../../../../assets/home-doctor.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const HomeDoctor = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={homeDoctor}
      heroLabel="The Home Doctor - Practical Medicine for Every Household"
      heroDescription="Discover life-saving tips from The Home Doctor: Practical Medicine
      for Every Household, co-authored by Dr. Maybell Nieves, Dr. Rodrigo
      Alterio, and Claude Davis."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            These invaluable tips will empower you to handle health emergencies
            when professional help is unavailable.{" "}
          </p>
          <p>
            Learn about must-have medical supplies, ingenious home treatments,
            foraging for wild edibles, and the healing secrets of Native
            Americans. With this guide, you can become a "home doc" and take
            charge of your health and well-being.
          </p>
          <p>
            This one-of-a-kind book was produced by physicians to assist
            individuals in taking care of their health issues at home when the
            medical system is no longer reliable.
          </p>
          <p>Grab your copy today to stay healthy and help others in need!</p>

          <CTAButton
            url="https://www.digistore24.com/redir/394659/ImportantMonk/"
            label="Get your book"
          />
          <p>
            You'll have a full 60 days to try the Home Doctor: Practical
            Medicine for Every Household.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default HomeDoctor;
