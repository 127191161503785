import React from "react";
import { Col, Row } from "react-bootstrap";
import BackButton from "../../components/BackButton/BackButton";

const NotFound = () => {
  return (
    <>
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="mb-4">
          <h2>
            It seems like the page you are looking for cannot be found. We
            apologize for any inconvenience caused.
          </h2>
        </Col>
        <Col md={{ span: 6, offset: 3 }} className="mb-4">
          <p>
            Double-check the URL for any typos or errors or visit our homepage
            to explore our website and discover other interesting pages.
          </p>
        </Col>
        <Col md={{ span: 6, offset: 3 }} className="mb-4">
          <BackButton url="/" label="Back to home" />
        </Col>
        <Col md={{ span: 6, offset: 3 }}>
          <p>
            We strive to provide you with the best browsing experience, and we
            appreciate your patience and understanding.
          </p>
          <p>Thank you for visiting our website!</p>
        </Col>
      </Row>
    </>
  );
};

export default NotFound;
