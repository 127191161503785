// import keto from "../../../assets/keto.webp";
// import transparent from "../../../assets/transparent.png";
import health from "../../../assets/health.webp";
import garden from "../../../assets/garden.webp";
import food from "../../../assets/food.webp";

export const homeModel = [
  {
    img: health,
    label: "Health",
    description:
      "In the tapestry of health, every nutrient is a brushstroke, painting a picture of resilience and strength. The heart beats with the rhythm of life, a steady drum that echoes the joy of existence. Breath, like a gentle breeze, carries the promise of renewal, filling our lungs with the elixir of vitality.",
    url: "/health",
    variant: "gradientOrangeTransparent",
    md: "7",
  },
  {
    img: garden,
    label: "Garden",
    url: "/garden",
    md: "5",
  },
  {
    img: food,
    label: "Food",
    url: "/food",
    md: "6",
    lg: "6",
    xl: "4",
  },
];
