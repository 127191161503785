import Router from "./Router";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container } from "react-bootstrap";
import Nav from "./components/Nav/Nav";
import { ThemeProvider } from "styled-components";
import themes from "./themes/commonTheme";
import Footer from "./components/Footer/Footer";

const App = () => {
  return (
    <ThemeProvider theme={themes}>
      <Container fluid>
        <Container>
          <Nav navFluid />
          <Router />
          <Footer />
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default App;
