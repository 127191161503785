import React from "react";
import { StyledCTAButton, StyledCTAButtonWrapper } from "./CTAButton.styled";

const CTAButton = ({ url, label }) => {
  return (
    <StyledCTAButtonWrapper>
      <StyledCTAButton href={url} target="_blank">
        {label}
      </StyledCTAButton>
    </StyledCTAButtonWrapper>
  );
};

export default CTAButton;
