import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import veganCookbook from "../../../../assets/vegan.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const VeganCookbook = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={veganCookbook}
      heroLabel="Revolutionize Your Diet with Mouthwatering Vegan Delights!"
      heroDescription="Our ultimate vegan cookbook is the answer to
      your quest for tasty and nutritious recipes that are both easy to
      prepare and satisfying to eat."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            Are you eager to embrace a more vegan-friendly diet but unsure where
            to begin? Your culinary journey starts here! Dive into the world of
            delectable vegan dishes that will tantalize your taste buds and
            leave you craving more.
          </p>
          <p>
            With over 300 mouthwatering recipes, you won't run out of exciting
            options to explore. Whether you're a seasoned vegan or new to
            plant-based eating, our cookbook caters to all. Bid farewell to dull
            and unappetizing meals – we've curated a collection that's brimming
            with vibrant flavors and irresistible textures.
          </p>
          <p>
            Unlock a world of benefits by adopting a vegan lifestyle. Not only
            will your heart health soar as you lower your risk of cardiovascular
            disease, but you'll also experience the rewards of weight loss and
            radiant skin. The magic lies in reducing saturated fats and
            embracing the richness of vitamins and nutrients found in
            plant-based ingredients.
          </p>
          <p>
            But that's not all - your journey extends beyond your plate. By
            going vegan, you'll play a vital role in animal welfare and
            environmental conservation. Say no to cruelty in the meat and dairy
            industries and contribute to a more sustainable world.
          </p>
          <p>
            Ready to embark on this exciting culinary adventure? Our cookbook
            comes with a variety of delectable options, from 150 vibrant vegan
            recipes bursting with natural goodness, to 100 sandwich creations
            that span from breakfast to dessert, and 50 high-protein salad
            inspirations to keep your meals fresh and fulfilling.
          </p>
          <p>
            <strong>
              Don't miss out on this incredible offer! Order now to claim your
              digital copy of the Vegan Based Cookbook. Act quickly, as this
              exclusive promotion won't last forever. With 97 out of 100 copies
              already claimed, seize the opportunity to revolutionize your diet
              and experience the benefits of vegan living.
            </strong>
          </p>

          <CTAButton
            url="https://www.digistore24.com/redir/443631/ImportantMonk/"
            label="Get your book"
          />
          <p>
            Plus, you're protected by our 100% satisfaction guarantee - if
            you're not delighted with your cookbook within{" "}
            <strong>60 days</strong>, you will be refunded.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default VeganCookbook;
