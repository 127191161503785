import React from "react";

import { Col, Row } from "react-bootstrap";

const Disclaimer = () => (
  <>
    <Row>
      <Col md={{ span: 8, offset: 2 }} className="mt-4 mb-5">
        <h1>Disclaimer</h1>
      </Col>
    </Row>
    <Row>
      <Col md={{ span: 8, offset: 2 }} className="mb-5">
        <p>
          This disclaimer page is designed to provide important information
          regarding the use of affiliate links on this website. By accessing and
          using this website, you acknowledge and agree to the terms and
          conditions outlined below.
        </p>
        <p>
          <strong>Affiliate Links:</strong> This website contain affiliate
          links, which means that when you click on these links and make a
          purchase, we may earn a commission at no additional cost to you. These
          commissions help support the maintenance and operation of this
          website.
        </p>
        <p>
          <strong>Product Recommendations:</strong> The product recommendations
          provided on this website are based on our own research, opinions, and
          experiences. However, it is important to note that individual
          experiences may vary. We encourage you to conduct your own research
          and make an informed decision before purchasing any product or service
          mentioned on this website.
        </p>
        <p>
          <strong>Accuracy and Completeness:</strong> While we strive to provide
          accurate and up-to-date information, the content on this website may
          contain errors, inaccuracies, or omissions. We make no representations
          or warranties of any kind, express or implied, about the accuracy,
          reliability, suitability, or availability of the information,
          products, services, or related graphics on this website. Any reliance
          you place on such information is therefore strictly at your own risk.
        </p>
        <p>
          <strong>External Websites:</strong> This website may contain links to
          external websites that are not under our control. We have no control
          over the nature, content, and availability of those sites. The
          inclusion of any links does not necessarily imply a recommendation or
          endorsement of the views expressed within them.
        </p>
        <p>
          <strong>Financial and Legal Advice:</strong> The information provided
          on this website is for general informational purposes only and should
          not be considered as professional financial or legal advice. We are
          not financial or legal professionals, and you should consult with a
          qualified professional before making any financial or legal decisions.
        </p>
        <p>
          <strong>Changes to the Disclaimer:</strong> We reserve the right to
          modify or update this disclaimer page at any time without prior
          notice. By continuing to use this website after any modifications or
          updates, you accept and agree to the revised terms.
        </p>
        <p>
          By using this website, you acknowledge that you have read, understood,
          and agreed to all the terms and conditions outlined in this disclaimer
          page.
        </p>
      </Col>
    </Row>
  </>
);

export default Disclaimer;
