import React from "react";
import { Col, Row } from "react-bootstrap";
import BackButton from "../../components/BackButton/BackButton";
// import CategoryCard from "../../components/CategoryCard/CategoryCard";
import CategoryCardVariant from "../../components/CategoryCardVariant/CategoryCardVariant";

import { fitnessPage } from "./model/fitnessPage";

const Fitness = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/" label="Back to home" />
      </Col>
    </Row>
    <Row className="mb-5">
      {fitnessPage.length &&
        fitnessPage.map(
          (
            { img, label, url, variant, description, sm, md, lg, xl, xxl },
            index
          ) => (
            <Col sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} key={index}>
              <CategoryCardVariant
                index={`${index} - ${label}`}
                img={img}
                label={label}
                url={url}
                variant={variant}
                description={description}
              />
            </Col>
          )
        )}
    </Row>
  </>
);

export default Fitness;
