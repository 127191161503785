import React from "react";
import {
  StyledBackButton,
  StyledLabel,
  StyledBackButtonWrapper,
} from "./BackButton.styled";
import arrow from "../../assets/arrow-left.svg";

const BackButton = ({ url, label }) => {
  const handleClick = () => {
    // eslint-disable-next-line react/destructuring-assignment
    window.location.href = url;
  };
  return (
    <StyledBackButtonWrapper onClick={handleClick}>
      <StyledBackButton>
        <img src={arrow} alt="arrow" />
      </StyledBackButton>
      <StyledLabel>{label}</StyledLabel>
    </StyledBackButtonWrapper>
  );
};

export default BackButton;
