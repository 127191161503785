import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";

import ScrollToTop from "./ScrollToTop";
import Fitness from "./pages/Fitness/Fitness";
import FitnessKetoPage from "./pages/Fitness/FitnessPages/FitnessKeto/FitnessKetoPage";
import FitnessKetoDessertsPage from "./pages/Fitness/FitnessPages/FitnessKetoDesserts/FitnessKetoDesserts";
import FitnessKetoCookbook from "./pages/Fitness/FitnessPages/FitnessKetoCookbook/FitnessKetoCookbook";
import Garden from "./pages/Garden/Garden";
import Food from "./pages/Food/Food";
import FitnessNaturalRemedies from "./pages/Fitness/FitnessPages/FitnessNaturalRemedies/FitnessNaturalRemedies";
import HealthEMFSticker from "./pages/Fitness/FitnessPages/HealthEMFSticker/HealthEMFSticker";
import Disclaimer from "./pages/Disclaimer/Disclaimer";
import NotFound from "./pages/NotFound/NotFound";
import HerbsForHealth from "./pages/Fitness/FitnessPages/HerbsForHealth/HerbsForHealth";
import HomeDoctor from "./pages/Fitness/FitnessPages/HomeDoctor/HomeDoctor";
import LostSuperFoods from "./pages/Fitness/FitnessPages/LostSuperFoods/LostSuperFoods";
import LostFrontier from "./pages/Fitness/FitnessPages/LostFrontier/LostFrontier";
import KetoSnacks from "./pages/Fitness/FitnessPages/KetoSnacks/KetoSnacks";
import CustomKetoDiet from "./pages/Fitness/FitnessPages/CustomKetoDiet/CustomKetoDiet";
import LosingWeight from "./pages/Fitness/FitnessPages/LosingWeight/LosingWeight";
import VeganCookbook from "./pages/Fitness/FitnessPages/VeganCookbook/VeganCookbook";
// import HomeNew from "./pages/HomeNew/HomeNew";
import UnlockYourBody from "./pages/Fitness/FitnessPages/UnlockYourBody/UnlockYourBody";
import AirFryerCookbook from "./pages/Fitness/FitnessPages/AirFryerCookbook/AirFryerCookbook";
import KetogenicDiet from "./pages/Fitness/FitnessPages/KetogenicDiet/KetogenicDiet";

const Router = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/health" element={<Fitness />} />

        <Route path="/health-keto" element={<FitnessKetoPage />} />
        <Route path="/health-emf-sticker" element={<HealthEMFSticker />} />
        <Route
          path="/health-natural-remedies"
          element={<FitnessNaturalRemedies />}
        />
        <Route path="/health-keto-cookbook" element={<FitnessKetoCookbook />} />
        <Route
          path="/health-keto-desserts"
          element={<FitnessKetoDessertsPage />}
        />
        <Route path="/herbs-for-health" element={<HerbsForHealth />} />
        <Route path="/home-doctor" element={<HomeDoctor />} />
        <Route path="/lost-superfoods" element={<LostSuperFoods />} />
        <Route path="/lost-frontier" element={<LostFrontier />} />
        <Route path="/keto-snacks" element={<KetoSnacks />} />
        <Route path="/custom-keto-diet" element={<CustomKetoDiet />} />
        <Route path="/losing-weight" element={<LosingWeight />} />
        <Route path="/vegan-cookbook" element={<VeganCookbook />} />
        <Route path="/unlock-your-body" element={<UnlockYourBody />} />
        <Route path="/air-fryer-cookbook" element={<AirFryerCookbook />} />
        <Route path="/ketogenic-diet" element={<KetogenicDiet />} />

        <Route path="/garden" element={<Garden />} />
        <Route path="/food" element={<Food />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="*" element={<NotFound />} />

        {/* <Route path="/new-home" element={<HomeNew />} /> */}
      </Routes>
    </ScrollToTop>
  );
};

export default Router;
