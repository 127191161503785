import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import emfSticker from "../../../../assets/emf-sticker.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const HealthEMFSticker = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={emfSticker}
      heroLabel="EMFDEFENSE™ Negative Ions Sticker"
      heroDescription="Is it true that cell phones are dangerous? Is that a conspiracy
      theory created by tinfoil-hat-wearing weirdos who wish to keep us in
      the dark ages? To be honest, I never gave it much thought... Not
      until a few people I respect began to voice concerns about the
      quantity of electromagnetic radiation we are exposed to on a daily
      basis."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            Then, without my will, I began to think about it. There have been
            several occasions when I've been speaking on my smartphone and
            realized that my ear is hot, my phone is hot, and the entire side of
            my head feels "off."{" "}
          </p>
          <p>
            Do you agree? What exactly is it? Is that what they mean when they
            talk about radiation?
          </p>
          <p>
            Is it why I've been experiencing so many headaches lately? I started
            doing some research... and I learned that there is plenty of
            scientific research indicating that mobile phones, WiFi, and 5G are
            hazardous to human health.
          </p>
          <p>
            Even if you're not using your phone, it's a well-known fact that it
            emits a continual stream of EMF radiation...
          </p>
          <p>Whether you use it actively or not.</p>
          <p>
            The good news is that you CAN protect yourself from the hazardous
            EMFs emitted by your phone! Click the button below to find out more!
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/448909/ImportantMonk/"
            label="Get your EMF Shield now"
          />
          <p>
            We're all aware that we spend much too much time on our phones. It's
            always at my side, even when I'm not using it.
          </p>
          <p>
            In the highly unlikely event that you're not completely happy with
            your purchase, just let us know within <strong>180 days</strong> and
            we'll refund your money for any reason... It's that simple. So go
            ahead and give our products a try today!
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default HealthEMFSticker;
