import React from "react";
import {
  StyledCategoryCard,
  StyledTitle,
  StyledDescription,
} from "./CategoryCardVariant.styled";

const CategoryCardVariant = ({ label, description, url, variant, img }) => {
  const handleClick = () => {
    // eslint-disable-next-line react/destructuring-assignment
    window.location.href = url;
  };
  return (
    <StyledCategoryCard
      onClick={handleClick}
      variant={variant}
      style={{ backgroundImage: `url(${img})` }}
    >
      <StyledTitle>{label}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledCategoryCard>
  );
};

export default CategoryCardVariant;
