import styled from "styled-components";

export const StyledImg = styled.img`
  position: relative;
  border-radius: ${(props) => props.theme.cardBorderRadius};
`;

export const StyledWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.textColorWhite};
  padding: 40px;
  border-radius: ${(props) => props.theme.cardBorderRadius};
  margin-left: -170px;
  margin-bottom: -30px;

  @media (max-width: 767px) {
    margin-left: unset;
    padding: 20px 0;
  }
`;

export const StyledH1Title = styled.h1`
  font-size: 45px;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 42px;
  }
`;
