import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import ketoEssentialCookbook from "../../../../assets/essential-keto.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const FitnessKetoCookbook = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={ketoEssentialCookbook}
      heroLabel="The Essential Keto Cookbook (Physical) - Free + Shipping"
      heroDescription="Introducing the mouthwatering delight you see above - the epitome of savory, scrumptious bread that will transport your taste buds to a realm of culinary bliss. Picture this: a crispy crust giving way to a light and fluffy interior. But wait, there's more - it's not just any bread; it's a sensational 100% KETO creation!"
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            Prepare yourself for a rich, buttery experience that will make you
            forget you're even on a diet. The aroma wafting from the oven? Pure
            HEAVEN. Your family will be clamoring for more, with requests like,
            "Please, can I have some more, Mom?"
          </p>
          <p>
            And it's not just about bread; how about diving into the delectable
            world of Keto Lasagna? Think layers of cheesy goodness infused with
            a symphony of flavors - basil, parsley, fennel, oregano, thyme,
            garlic, fresh tomatoes, and two types of meat. It's a culinary
            masterpiece that's oh-so-delicious!
          </p>
          <p>
            These aren't just comfort foods; each bite is a journey packed with
            healing vitamins, minerals, and fats. These are the foods you once
            thought you had to bid farewell to forever.
          </p>
          <p>
            Take, for example, the ginger spice cookies - a delightful treat
            that's 100% free from inflammatory ingredients. And yes, they're
            sweet enough to quell any sugar craving, yet low in carbs, so you'll
            never feel tempted to stray from your keto journey.
          </p>

          <p>
            But that's not all - this new cookbook is a treasure trove of
            mouth-watering Keto recipes, personally crafted to be both delicious
            and easy to make.
          </p>
          <p>
            Now, let's talk about the common struggles of weight loss. Are you
            tired of the treadmill, gym sweat sessions, and punishing yourself
            by avoiding your favorite foods? With this Essential Keto Cookbook,
            you can indulge, party, and enjoy delicious food while still burning
            that stubborn fat. And the best part? It's 100% FREE today!
          </p>
          <p>
            This cookbook features 106 flavorful recipes, stunning images,
            complete nutritional information, and a carb count for each dish. No
            confusion here - it even includes a keto food list. Plus, there's a
            14-day meal plan to kick-start or accelerate your keto weight loss
            journey.
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/269221/ImportantMonk/"
            label="Claim your free copy!"
          />
          <p>
            Exciting, right? As part of our book launch, They are giving away a
            limited number of copies for FREE. Just cover the shipping and
            handling, and they'll send it anywhere in the world.
          </p>
          <p>
            <strong>60-DAY Triple Money-Back Guarantee.</strong>
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default FitnessKetoCookbook;
