import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import UnlockYourBodyImg from "../../../../assets/unlock.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const UnlockYourBody = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={UnlockYourBodyImg}
      heroLabel="Transform Your Life in Just 5 Minutes a Day - Get Started Now!"
      heroDescription="This course claims to help
      individuals gain clarity in their thinking and make better decisions
      in daily life."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            The "Unlock Your Body" online course promises to enhance physical,
            mental, and emotional well-being through mobility routines, offering
            a holistic approach for personal growth. With just 5 to 15 minutes a
            day, it aims to help participants understand their bodies and
            experience a sense of freedom and peace.
          </p>
          <p>
            The course provides easy-to-follow video lessons, with over 75
            concise and effective videos suitable for all fitness levels, ages,
            and experiences. No special equipment is required, making it
            accessible to a wide audience. Additionally, participants can access
            extensive video and audio content, totaling 12+ hours of
            high-quality material.
          </p>
          <p>
            "Unlock Your Body" offers 25+ complete movement routines for daily
            use, with customizable routines ranging from 5 to 30 minutes to
            improve flexibility, strength, and overall health. The course also
            provides practical hacks on integrating movement into daily work,
            making it a convenient addition to one's routine.
          </p>
          <p>
            Participants can expect guided meditations as part of the holistic
            approach, promoting mindfulness practices that provide deeper
            insight into the body and overall well-being. The course includes
            additional downloadable resources such as exclusive worksheets,
            guides, and reading materials to support the transformative journey.
          </p>
          <p>
            The course highlights its community support, regular live sessions,
            a user-friendly app and browser platform, and the ability to
            progress at one's own pace without deadlines. It emphasizes proven
            results backed by testimonials and success stories, as well as a
            60-day refund policy. The overall message encourages individuals to
            unlock their potential by enrolling in the "Unlock Your Body" Online
            Course for a healthier, stronger, and more vibrant life.
          </p>
          <p>
            <strong>Get started now!</strong>
          </p>

          <CTAButton
            url="https://www.digistore24.com/redir/510562/ImportantMonk/"
            label="Get Started"
          />
          <p>
            Unlock Your Inner Power: The Secret to a Healthier, Happier You
            Revealed!
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default UnlockYourBody;
