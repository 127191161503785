import React from "react";
import { StyledLogo, StyledNav, StyledRow } from "./Nav.styled";
import { Col } from "react-bootstrap";

const Nav = ({ navFluid, navContainer }) => {
  return (
    <StyledNav>
      {navFluid && (
        <StyledRow>
          <Col>
            <nav className="navbar navbar-expand-lg bg-transparent">
              <div className="container-fluid px-0">
                <StyledLogo className="navbar-brand" href="/">
                  sync<span>.sale</span>
                </StyledLogo>
                <button
                  className="navbar-toggler shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-3 mb-2 mb-lg-0">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Categories
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="/health">
                            Health
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/garden">
                            Garden
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/food">
                            Food
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/disclaimer" className="nav-link">
                        Disclaimer
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a href="/new-home" className="nav-link">
                        New home page
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </Col>
        </StyledRow>
      )}
      {navContainer && (
        <StyledRow>
          <Col md={12} xl={{ span: 8, offset: 2 }}>
            <nav className="navbar navbar-expand-lg bg-transparent nav-container">
              <div className="container-fluid px-0">
                <StyledLogo className="navbar-brand" href="/">
                  sync<span>.sale</span>
                </StyledLogo>
                <button
                  className="navbar-toggler shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-3 mb-2 mb-lg-0">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Categories
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="/health">
                            Health
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/garden">
                            Garden
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/food">
                            Food
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/disclaimer" className="nav-link">
                        Disclaimer
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a href="/new-home" className="nav-link">
                        New home page
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </Col>
        </StyledRow>
      )}
    </StyledNav>
  );
};

export default Nav;
