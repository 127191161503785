import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import airfryer from "../../../../assets/airfryercookbook.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const AirFryerCookbook = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={airfryer}
      heroLabel="Discover the Secret to Crispy Keto Fried Foods That Burn Belly Fat"
      heroDescription="In the realm of ketogenic cuisine, the air fryer emerges as a game-changer, offering a revolutionary approach to preparing crispy and flavorful delights without compromising on the low-carb principles of the keto diet."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start px-0 mt-5" md={{ span: 8, offset: 2 }}>
          <p>
            The Keto Air Fryer Cookbook, a digital resource that offers 116
            oil-free, keto-friendly recipes. These recipes are specifically
            designed for air fryers and are known for their delicious flavors
            and quick preparation, with each dish taking less than 30 minutes to
            cook. The cookbook aims to provide a solution for individuals
            following a ketogenic (keto) diet who often crave fried foods.
          </p>
          <p>
            The challenge faced by individuals on a keto diet who are often
            tempted by fried foods. Fried foods, such as crispy onion rings,
            chicken nuggets, and pretzel sticks, are known for their
            irresistible appeal. These cravings are especially hard to resist
            when passing by fast food restaurants or encountering carb-filled,
            fried meals at social or work events. We here emphasizes the common
            temptation people face and the need for a solution.
          </p>
          <p>
            The adverse effects of consuming fried foods while on a keto diet.
            Book explains that indulging in fried foods can lead to a halt in
            the body's fat-burning process, causing weight gain and kicking the
            body out of ketosis, a metabolic state that promotes fat loss. The
            frustration of losing progress due to giving in to cravings is
            highlighted, underscoring the need for a sustainable solution.
          </p>

          <p>
            Solution: The Keto Air Fryer Cookbook as the solution to the
            dilemma. With this cookbook, individuals can enjoy their favorite
            fried foods while still maintaining ketosis and losing weight. It
            emphasizes the idea that fried foods, typically considered
            unhealthy, can actually aid in burning belly fat. The book conveys
            the author's strong belief that people shouldn't have to choose
            between their cravings and their health.
          </p>
          <p>
            The author, Zana, who is a mother of four and a Certified Health
            Coach, shares her own experience of being on a keto diet for two
            years and losing over 40 pounds. She, like others, struggled with
            the temptation of crispy, delicious fried foods while on the diet.
            This led her to explore the potential of air fryers in maintaining a
            keto lifestyle. Zana's journey and transformation provide a
            relatable and authentic touch to the text, emphasizing her
            commitment to helping others on their keto journeys.
          </p>
          <p>
            <strong>
              Discover the Secret to Crispy Keto Fried Foods That Burn Belly Fat
            </strong>
          </p>

          <CTAButton
            url="https://www.digistore24.com/redir/411008/ImportantMonk/"
            label="Grab Your Cookbook!"
          />
          <p>
            Plus, You Get Zero-Hassle, 100% Money-Back Guarantee within 60
            days!!
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default AirFryerCookbook;
