const themes = {
  cardBorderRadius: "26px",
  ctaBtnBorderRadius: "32px",

  primary: "#FD841F",
  secondary: "#95BDFF",
  tertiary: "#FF70A6",
  regentBlue: "#3AA6B9",
  textColorWhite: "#fff",
  textColorBlack: "#333",
  primaryHover: "#E14D2A",

  lightGrayStart: "#e2e5ed",
  lightGrayEnd: "#f1f4f4",

  violetStart: "rgba(181, 60, 255, 0.7)",
  violetEnd: "rgba(249, 82, 255, 0.7)",

  violetSolidStart: "rgba(181, 60, 255, 1)",
  violetSolidEnd: "rgba(249, 82, 255, 1)",

  orangeStart: "rgba(251, 171, 126, 0.7)",
  orangeEnd: "rgba(247, 206, 104, 0.7)",

  orangeSolidStart: "rgba(251, 171, 126, 1)",
  orangeSolidEnd: "rgba(247, 206, 104, 1)",

  greenBlueStart: "rgba(116, 235, 213, 1)",
  greenBlueEnd: "rgba(159, 172, 230, 1)",

  orangePinkStart: "rgba(251, 218, 97, 0.7)",
  orangePinkEnd: "rgba(255, 90, 205, 0.7)",

  pinkVioletBlueStart: "rgba(255, 60, 172, 1)",
  pinkVioletBlueMiddle: "rgba(120, 75, 160, 1)",
  pinkVioletBlueEnd: "rgba(43, 134, 197, 1)",
};

export default themes;
