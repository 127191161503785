import React from "react";
import { StyledH1Title, StyledImg, StyledWrapper } from "./PageHero.styled";
import { Col, Container, Row } from "react-bootstrap";

const PageHero = ({ src, heroDescription, heroLabel }) => {
  return (
    <Container fluid className="px-0">
      <Row className="d-flex align-items-end">
        <Col md={6}>
          <StyledImg className="img-fluid" src={src} alt={heroLabel} />
        </Col>
        <Col md={6}>
          <StyledWrapper>
            <StyledH1Title className="fw-bold mb-4">{heroLabel}</StyledH1Title>
            <p className="mb-0">{heroDescription}</p>
          </StyledWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default PageHero;
