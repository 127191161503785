// import keto from "../../../assets/keto.webp";
import transparent from "../../../assets/transparent.png";
import ketoDessert from "../../../assets/keto-dessert.webp";
import ketoCookbook from "../../../assets/keto-cook.webp";
import ketoNaturalRemedies from "../../../assets/natural-remedies-1.webp";
import emfSticker from "../../../assets/emf-sticker.webp";
import herbs from "../../../assets/herbs.webp";
import homeDoctor from "../../../assets/home-doctor-page.webp";
import lostSuperFood from "../../../assets/lost-superfood-page.webp";
import lostFrontier from "../../../assets/lost-frontier-page.webp";
import ketoSnacks from "../../../assets/keto-snacks.webp";
import customKetoDiet from "../../../assets/custom-keto-diet-page.webp";
import bloodSugar from "../../../assets/blood-sugar.webp";
import vegan from "../../../assets/vegan-page.webp";
import unlock from "../../../assets/unlock.webp";
import airfryer from "../../../assets/airfryercookbook.webp";
import ketogenicdietimg from "../../../assets/ketotonic-diet-home.webp";

export const fitnessPage = [
  {
    img: transparent,
    label: "The Ultimate Keto Meal Plan",
    description:
      "Have you considered going on a Ketogenic diet? And you not certain where to begin or how difficult it will be?",
    url: "/health-keto",
    variant: "gradientGray",
    md: "7",
  },
  {
    img: ketoDessert,
    label: "Keto After 50 - Desserts",
    url: "/health-keto-desserts",
    variant: "gradientOrangeTransparent",
    md: "5",
  },
  {
    img: ketoCookbook,
    label: "The Essential Keto Cookbook",
    url: "/health-keto-cookbook",
    variant: "gradientVioletTransparent",
    md: "6",
    lg: "6",
    xl: "4",
  },
  {
    img: ketoNaturalRemedies,
    label: "The Encyclopedia of Natural Remedies",
    url: "/health-natural-remedies",
    md: "6",
    lg: "6",
    xl: "4",
  },
  {
    img: emfSticker,
    label: "EMFDEFENSE™ Negative Ions Sticker",
    description:
      "Is it true that cell phones are dangerous? Is that a conspiracy theory created by tinfoil-hat-wearing weirdos who wish to keep us in the dark ages?",
    url: "/health-emf-sticker",
    variant: "gradientGreenBlue",
    md: "6",
    lg: "5",
    xl: "4",
  },
  {
    img: herbs,
    label: "Herbs for Health",
    url: "/herbs-for-health",
    md: "6",
    lg: "7",
    xl: "6",
  },
  {
    img: homeDoctor,
    label: "Home Doctor",
    url: "/home-doctor",
    md: "7",
    lg: "8",
    xl: "6",
  },
  {
    img: lostSuperFood,
    label: "The Lost SuperFoods",
    url: "/lost-superfoods",
    variant: "gradientOrangePink",
    md: "5",
    lg: "4",
    xl: "4",
  },
  {
    img: lostFrontier,
    label: "The Lost Frontier Handbook",
    url: "/lost-frontier",
    md: "5",
    lg: "6",
    xl: "8",
  },
  {
    img: ketoSnacks,
    label: "The Keto Snacks Cookbook",
    url: "/keto-snacks",
    md: "7",
    lg: "6",
    xl: "7",
  },
  {
    img: customKetoDiet,
    label: "Custom Keto Diet",
    url: "/custom-keto-diet",
    md: "6",
    xl: "5",
  },
  {
    img: bloodSugar,
    label: "Losing Weight and Activating Your Skinny Gene",
    url: "/losing-weight",
    md: "6",
    xl: "5",
  },
  {
    img: vegan,
    label: "300 Vegan/Plant Based Recipe Cook Book",
    url: "/vegan-cookbook",
    variant: "gradientPinkVioletBlue",
    md: "12",
    lg: "6",
    xl: "7",
  },
  {
    img: unlock,
    label: "Unlock Your Body",
    url: "/unlock-your-body",
    md: "6",
  },
  {
    img: airfryer,
    label: "The Keto Air Fryer Cookbook",
    url: "/air-fryer-cookbook",
    md: "6",
  },
  {
    img: ketogenicdietimg,
    label: "Ketogenic diet",
    description:
      "In a world filled with diet trends, the ketogenic diet has emerged as a powerful ally in the quest for health and wellness.",
    url: "/ketogenic-diet",
  },
];
