import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import { StyledH2Title } from "./KetogenicDiet.styled";

import KetogenicDietimg from "../../../../assets/ketotonic-diet.webp";
import ketogenicdiethome from "../../../../assets/ketotonic-diet-home.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const KetogenicDiet = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={ketogenicdiethome}
      heroLabel="Ketogenic Diet 101: Embracing a Healthier You!"
      heroDescription="In a world filled with diet trends, the ketogenic diet has emerged
              as a powerful ally in the quest for health and wellness."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start px-0" md={{ span: 8, offset: 2 }}>
          <StyledH2Title className="mt-5">
            How Ketogenic Diet Works
          </StyledH2Title>

          <p>
            At the heart of the ketogenic diet is the concept of ketosis. This
            metabolic state occurs when the body shifts from relying on
            carbohydrates for energy to burning fat. It's a natural process that
            has been harnessed for various health benefits.
          </p>

          <p>
            The reduction of carbohydrates is a defining factor in achieving
            ketosis. By limiting carb intake, the body is prompted to enter this
            fat-burning state, leading to a host of positive outcomes.
          </p>
          <StyledH2Title className="mt-5">
            Benefits of Ketogenic Diet
          </StyledH2Title>

          <p>
            One of the most celebrated aspects of the ketogenic diet is its
            effectiveness in weight management. Shedding those extra pounds
            becomes a byproduct of the body's reliance on fat for fuel.
          </p>

          <p>
            Advocates of the ketogenic lifestyle often report heightened mental
            clarity. The steady energy provided by ketones can lead to increased
            focus and cognitive function.
          </p>

          <p>
            Unlike the energy spikes and crashes associated with carb-heavy
            diets, the ketogenic approach provides sustained energy levels
            throughout the day.
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/526734/ImportantMonk/"
            label="Get your diet"
          />
          <StyledH2Title className="mt-5">
            Foods to Eat on a Ketogenic Diet
          </StyledH2Title>

          <p>
            Embracing healthy fats is a cornerstone of the ketogenic diet.
            Avocados, olive oil, and nuts are excellent sources of essential
            fats that support overall well-being.
          </p>

          <p>
            Quality protein is crucial for maintaining muscle mass. Incorporate
            lean meats, eggs, and dairy into your meals to meet your protein
            requirements.
          </p>

          <p>
            Non-starchy vegetables like spinach, kale, and broccoli are rich in
            nutrients and low in carbs, making them ideal choices for a
            ketogenic lifestyle.
          </p>
          <StyledH2Title className="mt-5">
            Foods to Avoid on a Ketogenic Diet
          </StyledH2Title>

          <p>
            Bid farewell to refined sugars and artificial sweeteners. These can
            disrupt ketosis and hinder progress.
          </p>

          <p>
            Say no to grains and starchy veggies, as they contribute to carb
            intake. Opt for cauliflower and zucchini as low-carb alternatives.
          </p>

          <p>
            Processed foods often contain hidden sugars and unhealthy fats.
            Stick to whole, unprocessed foods to optimize your ketogenic
            journey.
          </p>
          <img
            className="img-fluid mt-3 mb-4 round-corners"
            src={KetogenicDietimg}
            alt="vegan cookbook"
          />
          <StyledH2Title className="mt-5">
            Meal Planning and Recipes
          </StyledH2Title>

          <p>
            Kickstart your ketogenic journey with a sample meal plan that
            balances fats, proteins, and low-carb vegetables for a satisfying
            and nourishing experience.
          </p>

          <p>
            Explore delicious recipes that cater to the demands of a ketogenic
            lifestyle. From savory dishes to delectable desserts, there's a
            world of culinary delights to discover.
          </p>
          <StyledH2Title className="mt-5">
            Addressing Concerns and Misconceptions
          </StyledH2Title>

          <p>
            A well-planned ketogenic diet can provide all the essential
            nutrients. Understanding the right food choices is crucial to avoid
            any potential deficiencies.
          </p>

          <p>
            Contrary to misconceptions, scientific studies suggest that a
            properly executed ketogenic diet can be sustained in the long term
            without adverse effects.
          </p>
          <StyledH2Title className="mt-5">
            Combining Exercise with Ketogenic Diet
          </StyledH2Title>

          <p>
            Incorporating regular exercise into your routine enhances the
            benefits of the ketogenic diet. Discover the synergy between
            physical activity and ketosis.
          </p>

          <p>
            Fine-tune your macronutrient ratios based on your activity levels to
            optimize performance and recovery.
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/526734/ImportantMonk/"
            label="Get your diet"
          />
          <StyledH2Title className="mt-5">
            Success Stories and Testimonials
          </StyledH2Title>

          <p>
            Hear from individuals who have transformed their lives through the
            ketogenic diet. These real stories serve as inspiration for those
            embarking on a similar journey.
          </p>

          <p>
            Explore the diverse paths people have taken on their way to improved
            health and well-being. Every journey is unique, yet the destination
            is shared - a healthier, happier life.
          </p>
          <StyledH2Title className="mt-5">Tips for Beginners</StyledH2Title>

          <p>
            Ease into the ketogenic lifestyle by gradually reducing carb intake.
            This approach minimizes the chances of experiencing the "keto flu"
            and ensures a smoother transition.
          </p>

          <p>
            Adequate hydration is crucial on a ketogenic diet. Increase water
            intake to compensate for the loss of water associated with ketosis.
          </p>

          <p>
            Invest in ketone testing strips to monitor your ketone levels and
            ensure you stay within the desired range for optimal results.
          </p>

          <CTAButton
            url="https://www.digistore24.com/redir/526734/ImportantMonk/"
            label="Get your diet"
          />
          <StyledH2Title className="mt-5">Potential Side Effects</StyledH2Title>

          <p>
            Some individuals may experience flu-like symptoms during the initial
            stages of ketosis. Understanding and addressing these symptoms is
            key to a successful transition.
          </p>

          <p>
            Adjusting to a higher fat intake may cause temporary digestive
            issues. Gradual adaptation and choosing easily digestible foods can
            help alleviate discomfort.
          </p>

          <p>
            Maintain a balance of electrolytes, especially sodium, potassium,
            and magnesium, to prevent imbalances associated with the ketogenic
            diet.
          </p>
          <StyledH2Title className="mt-5">
            Professional Guidance and Monitoring
          </StyledH2Title>

          <p>
            Before embarking on any major dietary change, consult with a
            healthcare professional to ensure the ketogenic diet aligns with
            your individual health needs.
          </p>

          <p>
            Monitoring key health indicators through regular check-ups is
            essential to ensure that the ketogenic lifestyle continues to
            support your overall well-being.
          </p>
          <StyledH2Title className="mt-5">
            Staying Committed to the Ketogenic Lifestyle
          </StyledH2Title>

          <p>
            Every journey has its challenges. Discover practical tips and
            strategies to overcome obstacles and stay committed to the
            transformative
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/526734/ImportantMonk/"
            label="Get your diet"
          />
        </Col>
      </Row>
    </Container>
  </>
);

export default KetogenicDiet;
