import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import losingWeight from "../../../../assets/blood-sugar-page.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const LosingWeight = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={losingWeight}
      heroLabel="Lower Blood Sugar in Just Days!"
      heroDescription="Help lower blood sugar levels for diabetic individuals by flipping
      blood sugar switch back on. The book focuses on enjoying delicious
      food while still controlling blood sugar."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            This book includes testimonials from individuals who have
            successfully reduced their blood sugar levels and decreased or
            eliminated their reliance on insulin and medication. The text also
            offers a book called "Smart Blood Sugar" containing step-by-step
            instructions and additional bonus material, such as meal plans, food
            lists, and helpful tips for managing diabetes.
          </p>
          <p>
            The Diabetes Reversal Recipe is a 100% natural approach to lowering
            blood sugar levels. It allows individuals to enjoy their favorite
            foods while promoting the efficient burning of sugar in the
            bloodstream. The recipe involves a precise calibration of nutrients
            to activate the blood sugar "switch" and reverse insulin resistance.
            By following this recipe, individuals have reported significant
            drops in blood sugar levels.
          </p>
          <p>
            The book "Smart Blood Sugar" offers valuable information beyond the
            Diabetes Reversal Recipe. It provides practical, real-world advice
            on how to manage blood sugar effectively without resorting to
            restrictive diets or excessive calorie counting. The book includes
            helpful meal plans, ingredient shopping lists, and 99 foods known
            for their ability to lower blood sugar levels.
          </p>
          <p>
            For those seeking faster results, the text mentions two "heavy
            artillery" techniques to reset the blood sugar switch. The first
            involves specific movement exercises that quickly vacuum up excess
            glucose in the bloodstream. The second technique, based on an
            ancient religious practice, can lower blood sugar significantly for
            days. While these methods may require more effort, they have shown
            remarkable effectiveness in controlling blood sugar levels.
          </p>
          <p>
            One of the key aspects of the Diabetes Reversal Recipe is its focus
            on allowing individuals to enjoy delicious and satisfying meals
            without feeling deprived. Unlike restrictive diets, which can be
            difficult to maintain in the long term, this approach encourages a
            wide variety of foods that promote blood sugar control. By providing
            simple and tasty recipes, the plan makes it easier for individuals
            to stick to the program and experience the benefits of improved
            blood sugar levels while savoring their favorite dishes.
          </p>
          <p>
            The "Smart Blood Sugar" book goes beyond just meal planning and
            home-cooked recipes. It equips individuals with practical tips for
            navigating social situations, eating out at restaurants, and
            managing food choices in various settings. From ordering at a
            restaurant to selecting blood sugar-safe alcoholic drinks, the bonus
            guides offer valuable insights on maintaining blood sugar control
            while still enjoying a fulfilling social life. These tips empower
            individuals to make smart choices even when faced with tempting and
            less healthy food options.
          </p>
          <p>
            <strong>Get your book now!</strong>
          </p>

          <CTAButton
            url="https://www.digistore24.com/redir/490655/ImportantMonk/"
            label="Get your book"
          />
          <p>
            You have <strong>TWO MONTHS</strong> to test drive your custom meal
            plan.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default LosingWeight;
