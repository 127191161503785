import styled from "styled-components";

export const StyledCTAButton = styled.a`
  background-color: ${(props) => props.theme.primary};
  padding: 20px 50px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
  color: ${(props) => props.theme.textColorWhite};
  transition: background-color 0.5s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.textColorWhite};
    background-color: ${(props) => props.theme.primaryHover};
  }

  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const StyledCTAButtonWrapper = styled.div`
  padding: 30px 0 45px;
`;
