import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import ketoNaturalRemedies from "../../../../assets/natural-remedies.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const FitnessNaturalRemedies = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={ketoNaturalRemedies}
      heroLabel="The Encyclopedia of Natural Remedies"
      heroDescription="This 181-page eBook provides your natural health-conscious
      community/readers with over 300 traditional treatments, natural
      healing, and home remedies for more than 60 common maladies that may
      be performed by any layperson. So, if your intended demographic
      suffers from any of the 60 frequent diseases, this eBook will
      provide them with the knowledge they need to diagnose and manage
      some minor conditions that would otherwise be costly and time
      consuming to treat in specialized health care."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            In a groundbreaking revelation, we present to you a life-altering
            escape route from the shackles of a corrupt healthcare system. Brace
            yourself for the unveiling of 'Suppressed Medicine,' a clandestine
            healing power that has transformed lives across Japan, Indonesia,
            China, and the Middle East.
          </p>
          <p>
            These suppressed medical remedies are not just hearsay; they've been
            proven to reduce the persistent ringing in your ears (tinnitus²),
            alleviate joint discomfort, and foster a healthy inflammation
            response—all without relying on NSAIDs or conventional painkillers.
            Moreover, they have the remarkable ability to trigger your body's
            feel-good hormone³, GABA, acting as a natural painkiller and mood
            enhancer with zero side effects.
          </p>
          <p>
            The reason you've never stumbled upon these life-changing remedies
            is a clandestine one. The tentacles of big pharma have reached even
            the most trusted entities, such as doctors and pharmacies. The
            once-revered Hippocratic Oath, meant to protect patients, has been
            exploited to manipulate healthcare professionals. It's a shocking
            truth: the same oath used to justify horrific Nazi experiments is
            now being wielded by big pharma to control drug prices at the
            expense of your well-being.
          </p>
          <p>
            Today, doctors and pharmacists find themselves unwittingly entangled
            in the big pharma cartel, serving as mere distributors. A disturbing
            example comes from a New York pharmacist silenced by a 'Gag Order⁴'
            contract, preventing him from revealing affordable drug options to
            patients. Big pharma's control over the entire pharmaceutical supply
            chain enables them to artificially inflate prices and stifle
            dissenting voices.
          </p>
          <p>
            This exposé serves as a heartfelt confession from Dr. Freddie
            Masaki, or Mr. Freddie Masaki, as you might know him. He willingly
            stepped away from his medical profession, disillusioned by the
            corporate greed that had engulfed big pharma. Dr. Masaki witnessed
            firsthand the stark contrast between his meager earnings and the
            exorbitant income of the hospital CEO, making a staggering 1.43
            million dollars⁶ annually. This awakening moment prompted him to
            expose the malpractices and the heartbreaking consequences of
            blindly following the established healthcare system.
          </p>
          <p>
            Get The Encyclopedia of Natural Health Remedies now, and start using
            it. Click the button below to find out more!
          </p>
          <CTAButton
            url="https://www.digistore24.com/redir/446313/ImportantMonk/"
            label="Get your remedies now"
          />
          <p>
            If for some reason, you find that the remedies don't work for you,
            even though it's worked for every single person who's used these
            PROVEN ancient secrets - or you just decide that the breakthrough
            health secrets that you discover in The Encyclopedia of Natural
            Remedies are not for you, they are offering you a money-back
            guarantee that lasts for 60 days.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default FitnessNaturalRemedies;
