import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../../components/BackButton/BackButton";
import CTAButton from "../../../../components/CTAButton/CTAButton";

import lostFrontier from "../../../../assets/lost-frontier.webp";
import PageHero from "../../../../components/PageHeroAll/PageHero";

const LostFrontier = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/health" label="Back to Health Category" />
      </Col>
    </Row>
    <PageHero
      src={lostFrontier}
      heroLabel="Survive Any Crisis With the Long-Lost Skills of Our Ancestors"
      heroDescription="Learn how to create powerful disinfectants, painkillers, and
      remedies to treat wounds and sore throats without modern stores'
      help."
    />
    <Container className="mt-5 mt-sm-3 mb-5">
      <Row>
        <Col className="text-start mt-5 px-0" md={{ span: 8, offset: 2 }}>
          <p>
            Discover techniques for safely preserving meat, canning, pickling,
            and companion planting to ensure an endless food supply.
          </p>
          <p>
            Gain valuable insights on saving money on food, foraging for wild
            plants, raising chickens, and making household products.
          </p>
          <p>
            Unlock the long-lost survival skills of our ancestors with 'The Lost
            Frontier Handbook.'
          </p>
          <p>
            Inside, find powerful medicinal remedies you can make at home,
            techniques to stockpile food that never spoils, and vital tips for
            thriving through an economic collapse.
          </p>
          <p>
            <strong>Act now to secure this knowledge</strong>
          </p>

          <CTAButton
            url="https://www.digistore24.com/redir/382793/ImportantMonk/"
            label="Get your book"
          />
          <p>
            You have <strong>TWO FULL MONTHS</strong> to test drive 'The Lost
            Frontier Handbook', plus the three free bonuses, before you make
            your final decision.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default LostFrontier;
