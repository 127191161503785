import React from "react";
import { Col, Row } from "react-bootstrap";
import BackButton from "../../components/BackButton/BackButton";

const Garden = () => (
  <>
    <Row>
      <Col>
        <BackButton url="/" label="Back to home" />
      </Col>
    </Row>
    <Row>
      <Col className="mt-5">
        <h2>Stay tuned as we put the finishing touches on our Garden page.</h2>
      </Col>
    </Row>
  </>
);

export default Garden;
