import React from "react";
import { StyledFooter } from "./Footer.styled";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return <StyledFooter>© {currentYear} sync.sale</StyledFooter>;
};

export default Footer;
