import { Row } from "react-bootstrap";
import styled from "styled-components";

export const StyledNav = styled.div`
  /* margin-top: 48px;
  @media (max-width: 575px) {
    margin-top: 28px;
  } */
`;

export const StyledRow = styled(Row)`
  /* padding: 0 15px; */
`;

export const StyledLogo = styled.a`
  font-size: 50px;
  font-weight: 700;

  span {
    color: ${(props) => props.theme.primary};
  }
`;
