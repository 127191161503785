import styled, { css } from "styled-components";

export const StyledCategoryCard = styled.div`
  position: relative;
  padding: 40px;
  color: ${(props) => props.theme.textColorWhite};
  border-radius: ${(props) => props.theme.cardBorderRadius};
  min-height: 400px;
  margin-bottom: 30px;
  transition: transform 0.2s ease-in-out;
  background: no-repeat center center;
  background-size: cover;
  overflow: hidden;
  &:hover {
    transform: scale(1.009);
    cursor: pointer;
  }

  ${(props) =>
    props.variant === "default" &&
    css`
      background-color: transparent;
    `}

  ${(props) =>
    props.variant === "gradientGray" &&
    css`
      background: linear-gradient(
        20deg,
        ${(props) => props.theme.lightGrayStart},
        ${(props) => props.theme.lightGrayEnd}
      ) !important;
      color: ${(props) => props.theme.textColorBlack};
    `}

    ${(props) =>
    props.variant === "gradientVioletTransparent" &&
    css`
      color: ${(props) => props.theme.textColorWhite};
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          20deg,
          ${(props) => props.theme.violetStart},
          ${(props) => props.theme.violetEnd}
        ) !important;
      }
    `}
    ${(props) =>
    props.variant === "gradientViolet" &&
    css`
      color: ${(props) => props.theme.textColorWhite};
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          20deg,
          ${(props) => props.theme.violetSolidStart},
          ${(props) => props.theme.violetSolidEnd}
        ) !important;
      }
    `}
    ${(props) =>
    props.variant === "gradientOrangeTransparent" &&
    css`
      color: ${(props) => props.theme.textColorBlack};
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(
          62deg,
          ${(props) => props.theme.orangeStart} 0%,
          ${(props) => props.theme.orangeEnd} 100%
        ) !important;
      }
    `}
    ${(props) =>
    props.variant === "gradientOrange" &&
    css`
      color: ${(props) => props.theme.textColorBlack};
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(
          62deg,
          ${(props) => props.theme.orangeSolidStart} 0%,
          ${(props) => props.theme.orangeSolidEnd} 100%
        ) !important;
      }
    `}

    ${(props) =>
    props.variant === "gradientGreenBlue" &&
    css`
      color: ${(props) => props.theme.textColorBlack};
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(
          62deg,
          ${(props) => props.theme.greenBlueStart} 0%,
          ${(props) => props.theme.greenBlueEnd} 100%
        ) !important;
      }
    `}

    ${(props) =>
    props.variant === "gradientOrangePink" &&
    css`
      color: ${(props) => props.theme.textColorWhite};
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(
          62deg,
          ${(props) => props.theme.orangePinkStart} 0%,
          ${(props) => props.theme.orangePinkEnd} 100%
        ) !important;
      }
    `}

    ${(props) =>
    props.variant === "gradientPinkVioletBlue" &&
    css`
      color: ${(props) => props.theme.textColorWhite};
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(
          62deg,
          ${(props) => props.theme.pinkVioletBlueStart} 0%,
          ${(props) => props.theme.pinkVioletBlueMiddle} 50%,
          ${(props) => props.theme.pinkVioletBlueEnd} 100%
        ) !important;
      }
    `}
`;

export const StyledTitle = styled.h2`
  font-size: 30px;
  font-weight: 700;
  position: relative;
  z-index: 1;
`;

export const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-top: 25px;
  max-width: 450px;
  position: relative;
  z-index: 1;
`;
