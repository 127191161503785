import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Kumbh Sans', sans-serif;
    font-size: 18px;
  }

  .dropdown-menu {
    border-radius: 0 !important;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    width: 7px;
    background-color: #fd841f;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    top: 18px;
  }

  .nav-item {
    margin-left: 15px;
  }

  .navbar-toggler {
    border: 0;
  }

  .dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    background-color: #fd841f;
  }

  .round-corners {
    border-radius: 26px;
  }
`;

export default GlobalStyle;
